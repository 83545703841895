// colors
$base-color: #242448;
$accent-dark-color: #4a4a94;
$accent-light-color: #6a6ad4;

$color-dark-base: darken($base-color, 10%);
$color-light-base: lighten($base-color, 10%);

$color-white: #eee;
$color-gray-light: #ccc;
$color-gray-dark: #333;
$color-black: #111;
$color-gold: #94822d;
$color-gold-light: lighten($color-gold, 20%);
$color-gold-dark: darken($color-gold, 20%);
$color-teal: #1c92bd;
$color-teal-light: lighten($color-teal, 20%);
$color-teal-dark: darken($color-teal, 20%);

$color-green: #393;
$color-red: #933;
$color-yellow: #993;

// color specs
$text-color: $color-white;
$text-faded-color: $color-gray-light;
$link-color: $color-teal;
$link-hover-color: $color-teal-light;
$indicator-good-color: $color-green;
$indicator-bad-color: $color-red;
$indicator-neutral-color: $color-yellow;
