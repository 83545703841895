@import 'bootstrap/bootstrap.min.css';
@import 'fontawesome/fontawesome';	// sass-lint:disable-line clean-import-paths
@import 'fontawesome/solid';	// sass-lint:disable-line clean-import-paths

@import '_variables';
@import '_fonts';
@import '_tables';
@import '_forms';

html,
body {
	font-family: 'noto_sansregular';
	margin: 0;
	padding: 0;
}

body {
	background-color: $base-color;
	color: $text-color;
	overflow: hidden;
}

a {
	color: $text-color;

	&:link,
	&:visited {
		color: $link-color;
	}

	&:active,
	&:focus,
	&:hover,
	&:visited:active, // sass-lint:disable-line force-pseudo-nesting
	&:visited:focus, // sass-lint:disable-line force-pseudo-nesting
	&:visited:hover { // sass-lint:disable-line force-pseudo-nesting
		color: $link-hover-color;
	}
}

button[disabled], // sass-lint:disable-line force-attribute-nesting, no-qualifying-elements
input[disabled], // sass-lint:disable-line force-attribute-nesting, no-qualifying-elements
select[disabled] { // sass-lint:disable-line force-attribute-nesting, no-qualifying-elements
	cursor: not-allowed;
}

hr {
	border: 2px solid $accent-dark-color;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;

	li {
		margin: 0;
		padding: 0;
	}
}

.actions-container {
	align-content: space-between;
	display: flex;
	flex-flow: row nowrap;

	.left {
		flex: 1 1 auto;
		text-align: left;
	}

	.right {
		flex: 1 1 auto;
		text-align: right;
	}
}
