@font-face {
	font-family: 'noto_sansregular';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/notosans-regular-webfont.woff2') format('woff2'), url('/fonts/notosans-regular-webfont.woff') format('woff');
}

@font-face {
	font-family: 'raven_sans_nbpregular';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/raven2_sans-webfont.woff2') format('woff2'), url('/fonts/raven2_sans-webfont.woff') format('woff');
}

.bold {
	font-weight: bold;
}
